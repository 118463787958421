export const locale = {
  HEADING: "Perry W.T. Chan",
  SUBHEADING: "Psychotherapy, Counselling & Social Dreaming - UK & HK",
  TAB1: "About",
  TAB2: "therapy",
  TAB3: "session info",
  TAB4: "memories",
  TAB5: "social dreaming",
  WELCOMETITLE: "welcome",
  PROPIC: "When I was working in Malaysia",
  WELCOME: "Hi, you can call me Perry. I'm based in Edinburgh, Scotland. I provide in-person/online counselling & psychotherapy in English, Cantonese or Mandarin mainly for clients based in the UK and HK. I'm also a counselling tutor and a researcher (writing up my doctoral thesis at the moment) at the University of Edinburgh, as well as a certified Social Dreaming host.",
  CORETITLE: "my core value",
  CORE: "As I find labels such as xx disorder or xx syndrome pathologising and over-powering, I see each person as an individual with unique stories, experiences, personalities, behavioural patterns, complexes, psyches, souls......which are all meaningful, whether or not they're making sense at this moment. I'm committed to creating this safe space for us to explore together on a deeper level.",
  NAME: "Perry W.T. Chan, MBACP",
  QUALI1: "•	Doctorate in Psychotherapy & Counselling (DPsych; The University of Edinburgh) (In progress)",
  QUALI2: "•	Postgraduate Diploma in Psychotherapy & Counselling (PGDip; The University of Edinburgh)",
  QUALI3: "•	Certificate in Host of Social Dreaming (Social Dreaming international Network)",
  QUALI4: "•	Registered member of British Association for Counselling and Psychotherapy (BACP; Registration no. 388438)",
  QUALI5: "•	Certificate in Focusing Skills (The British Focusing Association)",
  PIC2: "Edinburgh, 2020 winter",
  EXPTITLE: "My Experience",
  EXP: "I specialise in early relational trauma (i.e. childhood abuse, neglect, and suffering). I work extensively with clients who had a difficult childhood, especially in relation to their family. Besides, I also have experience working with clients with a wide range of issues such as:",
  EXP1: "•	depression",
  EXP2: "•	anxiety",
  EXP3: "•	anger",
  EXP4: "•	stress",
  EXP5: "•	relationship difficulties",
  EXP6: "•	LGBT+ issues",
  EXP7: "•	sleeping problems",
  EXP8: "•	bereavement",
  EXP9: "•	loneliness",
  EXP10: "•	self-esteem",
  EXP11: "•	cross-cultural issues",
  GET1: "Get in Touch",
  E1: "perrywtchan@protonmail.com",
  COPY1: "© 2021, Perry W.T. Chan. All Rights Reserved.",
  APPTITLE1: "Therapy",
  APP1: "Having trained in psychodynamic and person-centred approaches to counselling, I aim to create a confidential, empathic and containing space between you and me, to explore and make sense of what is happening in life. I would describe therapeutic work as relational because speaking from my experience, I have found it fundamentally an authentic soul-to-soul encounter.",
  APP2: "Psychotherapy, in my opinion, isn't about fixing something. It's two people sitting together in dialogue trying to discover who we really are, what is perpetuating our dysfunctional thoughts or behaviours, and what is hindering us from developing a fuller sense of Self and feeling fulfilled. It is simple......but deep......",
  PIC3: "Two people navigating in the mist, Yellowcraig, 2021 summer",
  PIC4: "Old College, 2020 winter",
  APPTITLE2: "How it works",
  APP3: "Instead of someone who solves your problems, I'll be your companion being together in the chaotic and overwhelming space, indwelling ourselves in the experiences. We together try to process and hopefully come to terms with something painful and unbearable.",
  APP4: "My years of meditation practice and my training of focusing skills have allowed me and my clients to dwell in the experiences on a deeper level. When things start making sense and becoming meaningful, psychic transformation will be experienced. What might potentially happen are having higher self-awareness, bigger capacity to embrace the not-knowing in life and more ability to make better decisions without repeating unhealthy patterns.",
  APPTITLE3: "The Not-knowing",
  APP5: " . . . if you gaze for long into an abyss, the abyss gazes back into you.",
  APP6: "Nietzsche",
  APP7: "Imagine both of us trying to map a dark misty city together with a compass and find the way out. It could be an abyss of darkness, a fathomless realm, way beyond our capacity to understand.",
  APP8: "Yet, if we together explore long enough, our psyche will respond and it could end up a transformative journey. In our work, this is a safe space for us to let this alchemical reaction happen.",
  PIC5: "Misty Edinburgh, 2020 spring",
  PIC6: "Bird, Tokyo 2018 spring",
  APPTITLE4: "Therapeutic Process",
  APP9: "This is a poem I wrote at the beginning of my training.",
  APP10: "Two tiny eggs in the bird's nest",
  APP11: "Dream the undreamable",
  APP12: "Speak the unspeakable",
  APP13: "Feel the “unfeelable”",
  APP14: "Formulate the unformulated",
  APP15: "Experience the understandable",
  COPY2: "© 2021, Perry W.T. Chan. All Rights Reserved.",
  E2: "Get in Touch: perrywtchan@protonmail.com",
  PIC7: "A misty forest, Yellowcraig 2021 summer",
  PRACTITLE1: "Fees",
  PRAC1: "I provide service mainly for clients based in the UK (in-person/online/phone) and in Hong Kong (online only) at the rate as follows:",
  PRACTITLE2: "First & Following Sessions",
  PRAC2: "In our first meeting, we will get to know each other, talk about policies and how we can work together, and most importantly, see whether it feels right for you to work with me.",
  PRAC3: "When we agree to work together and on a mutually suitable time to meet, I undertake our work by providing regular weekly sessions.",
  PIC8: "A floating lighthouse, Yellowcraig 2021 summer",
  PIC9: "Google Map: https://goo.gl/maps/ontWZEiHSm22P3Cc6",
  PRACTITLE3: "Where?",
  PRAC4: "I offer face-to-face, online (Skype) or phone counselling session.",
  PRAC5: "For face-to-face session, I practice at:",
  PRAC6: "South Side Centre",
  PRAC7: "86 Causewayside",
  PRAC8: "Edinburgh",
  PRAC9: "EH9 1PY",
  COPY3: "© 2021, Perry W.T. Chan. All Rights Reserved.",
  E3: "Get in Touch: perrywtchan@protonmail.com",
  METITLE1: "Lost",
  ME1: "Born and grew up in Hong Kong, I later moved to the UK for higher education in my teenage years. Done my undergrad, done my master’s, feeling lost, hit the road, spent several years working and travelling around the world, looked for my soul, started personal therapy…….my journey started from here…",
  ME2: "“Perhaps one day you don’t need to go anywhere because you have the whole world in you?” A Jungian analyst spoke to me.",
  ME3: "“What do you mean?”",
  ME4: "“Like a monk meditating on a Mandala which encapsulates the whole universe.”",
  ME5: "“......I think I’m escaping from something......”",
  PIC10: "Koi fish, Tokyo 2018 spring",
  PIC11: "When you brush past your dark side it goes so quickly yet enlightens you",
  METITLE2: "Encountering",
  ME6: "This exchange occurred years ago.",
  ME7: "It was the time I started looking into myself, my inner process.",
  ME8: "Encountering my shadows, the dark sides of myself, my vulnerabilities…painful yet insightful.",
  ME9: "Digging deep, peeling off my wound, layer by layer......excoriating and excruciating......",
  METITLE3: "Koans",
  ME10: "“Yea I'm lost. What can I do?”",
  ME11: "“I don’t know” the analyst answered immediately.",
  ME12: "“…can you tell me what to do?”",
  ME13: "“Stop asking this question. When the right time has come, you will know what to do. Now stay reflective and curious.”",
  ME14: "Like a koan, solving it in our head is pointless. Indwelling ourselves in it is the way to understand it. This was the time when I learnt to indwell in my painful experience and started this life-long commitment to looking into my psyche and developing my self-awareness, as well as staying reflective on the tensions with any other beings.",
  PIC12: "Self-discovery, making the unconscious conscious",
  PIC13: "Meditation has become my daily practice. (Meditation in Japan, 2018)",
  METITLE4: "My Ways of Being",
  ME15: "Personal therapy and meditation are practices which keep me alive, reflective, creative and grounded and thus shapes my ways of being. This has thus allowed me to work with my clients ethically and professionally.",
  COPY4: "© 2021, Perry W.T. Chan. All Rights Reserved.",
  E4: "Get in Touch: perrywtchan@protonmail.com",
  SDTITLE1: "What is Social Dreaming?",
  SD1: "COMING SOON",

//SocialDreamingAbout//
tabAboutSdm: "About SDM",
tabLondonJuly: "July, London",
tabLondonDec: "Dec, London",
tabHkMay: "May, HK",

//SocialDreamingAbout//
  ullmanQuote: "Our dreaming self is more concerned with the nature of our connections with all others . . . is connected with the basic truth that we're all members of a single species . . . ",
  ullmanName: "Ullman, 1981",
  whatIsSDM: "What is a Social Dreaming Matrix (SDM)?",
  whatIsSDM_P1:"Lawrence (1999) pioneered the use of dream materials to illuminate social processes. Participants in a dream Matrix are invited to share their night dreams and to associate to the dreams through free associations, e.g, images, feelings, gut reactions, memories, thoughts, another dream, fragments of dreams, books, films, poems, etc.  Through the dreams and associations, we find links, make connections and discover new thoughts. In a Matrix, it’s about the dream, not the dreamer. And no dream is more important than any other.",
  whatIsSDM_P2: "A Matrix is usually followed by a Dream Reflection Dialogue (DRD) where we explore and identify emotions, thoughts and themes that emerged in the Matrix, and thus underlying issues of social systems can be explored.",
  dreamingMushrooms2: "Dreaming Mushrooms (Perry Chan 2022)",
  dreamingFishes: "Dreaming fishes (Perry Chan 2022)",
  SocialDreamingAbout_ref: "Reference",

//SocialDreaming/LondonJuly//
  londonJuly_EventName: "Hong Kong Social Dreaming Matrix - A Space to Dream, to Imagine, to Make the Unthought Known",
  londonJuly_sdDate: "July 30 & Aug 6 (Sat), 2022, 11am-3pm, London",
  ullmanFullName: "Montague Ullman",
  londonJuly_eventIntro: "Hong Kong is experiencing an unprecedented level of turmoil. How has this ongoing turmoil been impacting on, threatening and shaping Hongkongers’ sense of identity, psyche, social and cultural complex, and so on? Are you interested in exploring this on a deeper – unconscious and collective level? And curious to experience a different kind of thinking space?",
  londonJuly_freeEvent: "This is a FREE event, and the hosts are not paid for hosting this SDM.",
  londonJuly_contact: "To join us, or for any inquiries, please contact:",
  londonJuly_email: "perrywtchan@protonmail.com",
  dreamingMushrooms: "Dreaming Mushrooms (Perry Chan 2022)",
  refer: "Please refer to",
  learnMore: "to learn more.",
  whatIsSDM_intro: "In this SDM, no previous experience and knowledge of SD is required.  In the introductory session, we will briefly introduce SD, e.g., the meaning of dream sharing, collective unconscious, the way we connect to each other on a deep level, etc. We are hosting these 2 weekends of 4 Social Dreaming Matrices in a comfortable setting, and near cafes where we will be able to take our lunch break.",
  CanIParticipate: "Can I participate?",
  londonJuly_participate_invite: "We are inviting 10 people to join this Matrix. You are invited if you:",
  londonJuly_Participate_c1: "•	are interested in exploring the Hong Kong turmoil",
  londonJuly_Participate_c2: "•	perceive yourself as a Hongkonger",
  londonJuly_Participate_c3: "•	are over 18 years old",
  londonJuly_Participate_c4: "•	can communicate in English",
  londonJuly_Participate_c5: "•	are willing to share and engage with dreams or associations to whatever emerges in the Matrix to all participants",
  sdmNotTherapy: "SDM is NOT therapy. It is a safe and non-confrontational space for us to share dreams and other associated thoughts. Participants need to take responsibility for their level of participation and well-being.",
  londonJuly_venue: "Venue",
  londonJuly_venueDistance: "(6-min walk from Tube station Swiss Cottage)",
  londonJuly_agenda: "Agenda",
  londonJuly_agendaNote1: "Note: DRD refers to Dream Reflection Dialogue. To learn more, please refer to ",
  londonJuly_agendaNote2: "; Participants should arrive at 10:45am so that the SDM can begin promptly at 11am.",
  londonJuly_confidentiality: "Confidentiality",
  londonJuly_confidentiality_p1: "No personal information will be collected and stored. The introductory session and the SD sessions will NOT be audio- or video- recorded. The hosts will take notes or draw during the sessions and this process will be digitally recorded in the device (only the screen).",
  londonJuly_confidentiality_p2: "However, any personal details and any elements which can be associated to the identity of any participants will be omitted. It is every participant’s responsibility, including the hosts, if speaking of thoughts and idea from within the Matrix, to anonymous them, and have respect for confidentiality.",
  londonJuly_AboutHost: "About the Hosts",
  londonJuly_AboutHost_perry: "Perry WT Chan has worked as a relational psychotherapist in NGOs and privately, in the UK and remotely in HK. He is particularly interested in early relational (childhood) trauma, and societal collective trauma. He is also a counselling tutor and a researcher (writing his doctoral thesis at the moment) at the University of Edinburgh.",
  londonJuly_AboutHost_claire: "Claire Manson, an artist and art psychotherapist has worked in the NHS and independent sector for over 40 years. She co-founded 'Studio Upstairs', a working arts studio and therapeutic space and has also worked, and volunteered, and continues to do so as a psychotherapist for human rights organisations.",
  
  //SocialDreaming/LondonDec//
  londonDec_EventName1: "Alchemical River: Hong Kong Social Dreaming Matrix",
  londonDec_EventName2: " - A Space to Dream, to Imagine, to Make the Unthought Known",
  londonDec_sdDate1: "Dec 10 (Sat), 2022, London",
  londonDec_sdDate2: ";15:20-16:30 (1st Matrix)",
  londonDec_sdDate3: ";17:30-18:40 (2nd Matrix)",
  ullmanFullName: "Montague Ullman",
  londonDec_eventIntro: "A Social Dreaming Matrix is a creative space for dreams to speak, breed and unfold, and deeper meanings to emerge, from the social unconscious and collective level. You are invited to join us in this free-flowing moment where we listen to our night-time dreams and associations together, to connect & explore social issues & discover new perspectives. This is about the dream, not the dreamer, and no dream is more important than any other.",
  londonJuly_freeEvent: "This is a FREE event, and the hosts are not paid for hosting this SDM.",
  londonJuly_contact: "To join us, or for any inquiries, please contact:",
  londonJuly_email: "perrywtchan@protonmail.com",
  dreamingMushrooms: "Dreaming Mushrooms (Perry Chan 2022)",
  refer: "Please refer to",
  learnMore: "to learn more.",
  whatIsSDM_intro_londonDec: "Are you interested in exploring this on a deeper – unconscious and collective level? And curious to experience a different kind of thinking & talking space? No previous experience and knowledge of SD is required.",
  CanIParticipate: "Can I participate?",
  londonJuly_participate_invite: "We are inviting 10 people to join this Matrix. You are invited if you:",
  londonJuly_Participate_c1: "•	are interested in exploring the Hong Kong turmoil",
  londonJuly_Participate_c2: "•	perceive yourself as a Hongkonger",
  londonJuly_Participate_c3: "•	are over 18 years old",
  londonJuly_Participate_c4: "•	can communicate in English",
  londonJuly_Participate_c5: "•	are willing to share and engage with dreams or associations to whatever emerges in the Matrix to all participants",
  sdmNotTherapy: "SDM is NOT therapy. It is a safe and non-confrontational space for us to share dreams and other associated thoughts. Participants need to take responsibility for their level of participation and well-being.",
  londonJuly_venue: "Venue",
  londonJuly_venueDistance_londonDec: "(4-min walk from Belsize Park Underground Station)",
  londonJuly_agenda: "Agenda",
  londonJuly_agendaNote1: "Note: DRD refers to Dream Reflection Dialogue. To learn more, please refer to ",
  londonJuly_agendaNote2: "; Participants should arrive at 10:45am so that the SDM can begin promptly at 11am.",
  londonJuly_confidentiality: "Confidentiality",
  londonJuly_confidentiality_p1: "No personal information will be collected and stored. The introductory session and the SD sessions will NOT be audio- or video- recorded. The hosts will take notes or draw during the sessions and this process will be digitally recorded in the device (only the screen).",
  londonJuly_confidentiality_p2: "However, any personal details and any elements which can be associated to the identity of any participants will be omitted. It is every participant’s responsibility, including the hosts, if speaking of thoughts and idea from within the Matrix, to anonymous them, and have respect for confidentiality.",
  londonJuly_AboutHost: "About the Hosts",
  londonJuly_AboutHost_perry: "Perry WT Chan has worked as a relational psychotherapist in NGOs and privately, in the UK and remotely in HK. He is particularly interested in early relational (childhood) trauma, and societal collective trauma. He is also a counselling tutor and a researcher (writing his doctoral thesis at the moment) at the University of Edinburgh.",
  londonJuly_AboutHost_claire: "Claire Manson, an artist and art psychotherapist has worked in the NHS and independent sector for over 40 years. She co-founded 'Studio Upstairs', a working arts studio and therapeutic space and has also worked, and volunteered, and continues to do so as a psychotherapist for human rights organisations.",

  //SocialDreaming/HkMay//
  hkMay_EventName1: "Social Dreaming Matrix – An Experiential Workshop",
  hkMay_EventName2: " - A Space to Dream, to Imagine, to Make the Unthought Known",
  hkMay_sdDate1: "May 17 (Fri), 2023, 19:00-21:00; Hong Kong",
  hkMay_sdDate2: ";19:00-19:20 Welcoming",
  hkMay_sdDate3: ";19:20-20:05 Matrix",
  hkMay_sdDate4: ";20:05-20:20 Dream reflection dialogue",
  hkMay_sdDate5: ";20:20-21:00 Open panel discussion/reflection",
  ullmanFullName: "Montague Ullman",
  londonDec_eventIntro: "A Social Dreaming Matrix is a creative space for dreams to speak, breed and unfold, and deeper meanings to emerge, from the social unconscious and collective level. You are invited to join us in this free-flowing moment where we listen to our night-time dreams and associations together, to connect & explore social issues & discover new perspectives. This is about the dream, not the dreamer, and no dream is more important than any other.",
  hkMay_freeEvent: "This is a FREE event.",
  londonJuly_contact: "To join us, or for any inquiries, please contact:",
  londonJuly_email: "perrywtchan@protonmail.com",
  dreamingMushrooms: "Dreaming Mushrooms (Perry Chan 2022)",
  refer: "Please refer to",
  learnMore: "to learn more.",
  whatIsSDM_intro_londonDec: "Are you interested in exploring this on a deeper – unconscious and collective level? And curious to experience a different kind of thinking & talking space? No previous experience and knowledge of SD is required.",
  CanIParticipate: "Can I participate?",
  londonJuly_participate_invite: "We are inviting 10 people to join this Matrix. You are invited if you:",
  londonJuly_Participate_c1: "•	are interested in exploring the Hong Kong turmoil",
  londonJuly_Participate_c2: "•	perceive yourself as a Hongkonger",
  londonJuly_Participate_c3: "•	are over 18 years old",
  londonJuly_Participate_c4: "•	can communicate in English",
  londonJuly_Participate_c5: "•	are willing to share and engage with dreams or associations to whatever emerges in the Matrix to all participants",
  sdmNotTherapy: "SDM is NOT therapy. It is a safe and non-confrontational space for us to share dreams and other associated thoughts. Participants need to take responsibility for their level of participation and well-being.",
  londonJuly_venue: "Venue",
  londonJuly_venueDistance_londonDec: "(4-min walk from Belsize Park Underground Station)",
  londonJuly_agenda: "Agenda",
  londonJuly_agendaNote1: "Note: DRD refers to Dream Reflection Dialogue. To learn more, please refer to ",
  londonJuly_agendaNote2: "; Participants should arrive at 10:45am so that the SDM can begin promptly at 11am.",
  londonJuly_confidentiality: "Confidentiality",
  londonJuly_confidentiality_p1: "No personal information will be collected and stored. The introductory session and the SD sessions will NOT be audio- or video- recorded. The hosts will take notes or draw during the sessions and this process will be digitally recorded in the device (only the screen).",
  londonJuly_confidentiality_p2: "However, any personal details and any elements which can be associated to the identity of any participants will be omitted. It is every participant’s responsibility, including the hosts, if speaking of thoughts and idea from within the Matrix, to anonymous them, and have respect for confidentiality.",
  londonJuly_AboutHost: "About the Hosts",
  londonJuly_AboutHost_perry: "Perry WT Chan has worked as a relational psychotherapist in NGOs and privately, in the UK and remotely in HK. He is particularly interested in early relational (childhood) trauma, and societal collective trauma. He is also a counselling tutor and a researcher (writing his doctoral thesis at the moment) at the University of Edinburgh.",
  londonJuly_AboutHost_claire: "Claire Manson, an artist and art psychotherapist has worked in the NHS and independent sector for over 40 years. She co-founded 'Studio Upstairs', a working arts studio and therapeutic space and has also worked, and volunteered, and continues to do so as a psychotherapist for human rights organisations.",




  





}